<template>
  <div class="DashBoard">
    <div v-if="carregando == true" class="carregando">
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>
    <div>
      <Topo @OpenMenuLateral="menuOpen = true"></Topo>
      <b-row
        :style="
          `background-color:${temaCor.topoApp};color:${temaCor.CorItensCabecalho}`
        "
      >
        <b-col class="container"
          ><h4 class="my-3 ml-3">Sobre o Clube Certo</h4></b-col
        >
      </b-row>

      <div class="container mt-3">
        <b-row>
          <b-col cols="1"
            ><i
              class="fa fa-instagram icone"
              :style="`color:${temaCor.topoApp};`"
            ></i
          ></b-col>
          <b-col class="container">
            <a :href="'http://instagram.com/clubecerto'" target="_blank">
              /clubecerto</a
            >
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="1"
            ><i
              class="fa fa-facebook icone"
              :style="`color:${temaCor.topoApp};`"
            ></i
          ></b-col>
          <b-col class="container">
            <a :href="'http://facebook.com/clubecertooficial'" target="_blank">
              /clubecertooficial</a
            >
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="1"
            ><i
              class="fa fa-map-marker icone"
              :style="`color:${temaCor.topoApp};`"
            ></i
          ></b-col>
          <b-col class="container">
            <a :href="'https://g.page/clubecerto?share'" target="_blank">
              Av. Altamiro Avelino Soares, 1061 - Castelo, Belo Horizonte -
              MG</a
            >
          </b-col>
        </b-row>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3752.057246414665!2d-44.00574048451992!3d-19.8798044866319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa6919b0acb5b1d%3A0x2fd4e5697a25ccc9!2sClube%20Certo!5e0!3m2!1spt-BR!2sbr!4v1653494675496!5m2!1spt-BR!2sbr"
          width="100%"
          height="350"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          class="mt-4"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>

        <b-row class="mt-3" style="margin-bottom:100px">
          <b-col class="container text-center">
            <a
              :href="'http://www.clubecerto.com.br'"
              target="_blank"
              class="linksite"
              ><i class="fa fa-globe icone"></i> Ir para o Site</a
            >
          </b-col>
        </b-row>
      </div>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from "jsonwebtoken";
import MenuRodape from "./MenuRodape";
import Topo from "./Topo";
import MenuLateral from "./MenuLateral";
import { Carousel, Slide } from "vue-carousel";
import service from "../services";
import moment from "moment";
export default {
  name: "DashBoard",
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      flipped: false,
      dados: {},
      temaCor: {},
      carregando: false,
      heightGeral: { height: "375px" },
      nome: null,
      cpf: null,
      cartaoFrente: null,
      cartaoVerso: null,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },
  components: { Topo, MenuRodape, MenuLateral, Carousel, Slide },
  methods: {
    toggleCard() {
      this.flipped = !this.flipped;
    },
    formataRegional(id) {
      if (id) return id.toString().padStart(4, "0");
      else return "0000";
    },
    formataId(id) {
      if (id) return id.toString().padStart(6, "0");
      else return "000000";
    },
    formataDesde(data) {
      if (data) return moment(data).format("MM/YYYY");
    },
    formataAno(data) {
      if (data) return moment(data).format("YYYY");
    },
    returnaStyle() {
      var obj = {};
      obj.width = `${this.windowWidth}px`;
      obj.height = `${this.windowHeight - 120}px`;
      if (this.windowWidth > this.windowHeight)
        obj.height = `${this.windowHeight + 300}px`;
      return obj;
    },
    verificaToken() {
      var dadosEmpresa = JSON.parse(localStorage.getItem("dadosEmpresa"));
      const nome = localStorage.getItem("nome");
      const cpf = localStorage.getItem("cpf");
      this.temaCor.topoApp = dadosEmpresa.CorBackgroundCabecalho;
      this.temaCor.CorItensCabecalho = dadosEmpresa.CorItensCabecalho;
      this.temaCor.CorTextoCartaoVirtual = dadosEmpresa.CorTextoCartaoVirtual;
      console.log(this.temaCor);
      this.cartaoFrente = dadosEmpresa.Frente;
      this.cartaoVerso = dadosEmpresa.Verso;
      const token = localStorage.getItem("user-token");
      if (token) {
        this.decoded = jwt.decode(token);
        this.nome = nome;
        this.cpf = cpf;
      }
    },
    getSize() {
      console.log(this.heightGeral);
      this.heightGeral.height = `${screen.height - 10}px`;
      console.log("////////////////////", document.height, screen.height);
      console.log(this.heightGeral);
    },
  },
  mounted() {
    this.verificaToken();
    this.getSize();
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
body {
  overflow-x: hidden;
}
.container {
  font-size: 15px;
}
a {
  color: #333;
}
.icone {
  font-size: 26px;
}

.linksite {
  border: 1px solid #333;
  border-radius: 10px;
  padding: 10px;
  i {
    margin-right: 10px;
  }
}
</style>
